import React from "react";
import async from "../components/Async";
import { Users,File } from "react-feather";

const Admin = async(() => import("../pages/users/admin"));
const AdminDDA = async(() => import("../pages/ddauser/ddaAdmin"));
const AdminThrive = async(() => import("../pages/thriveuser/thriveAdmin"));
const AdminDserve = async(() => import("../pages/dserveuser/dserveAdmin"));


const Nomination = async(() => import("../pages/Nomination/nomination"));
const NominationDDA = async(() => import("../pages/ddanomination/nomination"));
const NominationThrive = async(() => import("../pages/thrivenomination/nomination"));
const NominationDserve = async(() => import("../pages/dservenomination/nomination"));


const NominationID = async(() => import("../pages/Nomination/idNomination"));
const NominationIDDA = async(() => import("../pages/ddanomination/idNomination"));
const NominationIDThrive = async(() => import("../pages/thrivenomination/idNomination"));
const NominationIDDserve = async(() => import("../pages/thrivenomination/idNomination"));


const ViewNom = async(() => import("../pages/View/index"));
const ViewNomDDA = async(() => import("../pages/ViewDDA/indexDDA"));
const ViewNomThrive = async(() => import("../pages/viewThrive/indexThrive"));
const ViewNomDserve = async(() => import("../pages/viewDserve/indexDserve"));


const AuthGuard = async(() => import("../components/AuthGuard"));
// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ForgotPassword = async(() => import("../pages/auth/ForgotPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const nomination = {
  id: "Nameawards Nomination",
  path: "/nomination",
  icon: <File />,
  containsHome: true,
  children: null,
  component: Nomination,
};

const users = {
  id: "Nameawards Jurors",
  path: "/jurror",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: Admin,
};
const ddanomination = {
  id: "DDA Nomination",
  path: "/ddanomination",
  icon: <File />,
  containsHome: true,
  children: null,
  component: NominationDDA,
};

const ddausers = {
  id: "DDA Jurors",
  path: "/ddajurror",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: AdminDDA,
};
const dservenomination = {
  id: "Dserve Nomination",
  path: "/dservenomination",
  icon: <File />,
  containsHome: true,
  children: null,
  component: NominationDserve,
};
const dserveusers = {
  id: "Dserve Jurors",
  path: "/dservejurror",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: AdminDserve,
};

const thrivenomination = {
  id: "Thrive Nomination",
  path: "/thrivenomination",
  icon: <File />,
  containsHome: true,
  children: null,
  component: NominationThrive,
};

const thriveuser = {
  id: "Thrive Jurors",
  path: "/thrivejurror",
  icon: <Users />,
  containsHome: true,
  children: null,
  component: AdminThrive,
};


const IdNomination = {
  id: "Nominations",
  path: "/assignments",
  icon: <File />,
  containsHome: true,
  children: null,
  component: NominationID,
};

// const IdNominationDDA = {
//   id: "Nominations",
//   path: "/ddaassignments",
//   icon: <File />,
//   containsHome: true,
//   children: null,
//   component: NominationIDDA,
// };
// const IdNominationThrive = {
//   id: "Nominations",
//   path: "/thriveassignments",
//   icon: <File />,
//   containsHome: true,
//   children: null,
//   component: NominationIDThrive,
// };

// const IdNominationDserve = {
//   id: "Nominations",
//   path: "/dserveassignments",
//   icon: <File />,
//   containsHome: true,
//   children: null,
//   component: NominationIDDserve,
// };


const View = {
  id: "View",
  path: "/view",
  containsHome: true,
  children: null,
  component: ViewNom,
};
const ViewDDA = {
  id: "ViewDDA",
  path: "/viewDDA",
  containsHome: true,
  children: null,
  component: ViewNomDDA,
};

const ViewThrive = {
  id: "ViewThrive",
  path: "/viewThrive",
  containsHome: true,
  children: null,
  component: ViewNomThrive,
};

const ViewDserve = {
  id: "ViewDserve",
  path: "/viewDserve",
  containsHome: true,
  children: null,
  component: ViewNomDserve,
};


const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <Users />,
  children: [
    {
      path: "/",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/forgot-password",
      name: "Forgot Password",
      component: ForgotPassword,
    },

    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [nomination,users,ddanomination,ddausers,dservenomination,dserveusers,thrivenomination,thriveuser,IdNomination,View,ViewDDA,ViewThrive,ViewDserve];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [nomination, users,ddanomination,ddausers,dservenomination,dserveusers,thrivenomination,thriveuser,IdNomination];
